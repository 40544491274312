.about_section {
    padding: 100px;
    background-color: #f2f2f2;
    text-align: justify;

    .abtdbse {
        width: 60px;
        font-size: 1.8rem;
        // padding-bottom: 80px;

        span {
            color: #5b5bc1;
        }
    }

    .imgdata {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        .groupstudentpic {
            img {
                margin-top: -50px;
                width: 500px;
                // height: 400px;

            }
        }
    }


    .imgnextdata {
        padding-top: 20px;
        font-size: 1.2rem;
        // text-align: left;
        display: flex;
    }

    .datalist {
        padding-top: 20px;
        font-size: 1.2rem;
        // text-align: left;
    }

    .partnrIB {
        padding-top: 20px;
        font-size: 1.2rem;
        // text-align: left;
        span {
            color: #5b5bc1;
        }
    }

    .partnrIS {
        padding-top: 20px;
        font-size: 1.2rem;
        // text-align: left;
    }

    .dbsefocus {
        padding-top: 20px;
        font-size: 1.2rem;
        // text-align: left;
    }
}

.VisionImage {
    img {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .about_section {
        padding: 25px;

        .abtdbse {
            padding-bottom: 25px;
            width: auto;
        }

        .imgdata {
            flex-direction: column-reverse;
            text-align: justify;

            .groupstudentpic {
                img {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                }
            }
        }

        .imgnextdata {
            text-align: justify;
        }
    }
}