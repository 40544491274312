.specialization {
    background-color: #f2f2f2;

    .horizontal {
        border-top: solid 3px #0c0c0d;
        width: 100%;
        height: 5px;
    }

    .banner {
        position: relative;
        margin: 0;
        padding: 0;
        margin-bottom: -10px;

        img {
            width: 100%;
        }

        .inner-breadcrum-container {
            position: absolute;
            bottom: 45px;
            color: #122c5f;
            font-size: 2rem;
            font-weight: 600;
            background-color: rgba($color: #ffffff, $alpha: 0.7);
            padding: 13px 50px 20px 150px;
            border: solid 3px #8298ed;
            border-left: none;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;

            &:hover {
                padding-left: 40%;
                transition: all 1s;

                .inner-breadcrum {
                    display: none;
                    transition: all 1s;
                }

                .inner-breadcrum-hover {
                    transition: all 1s;
                    display: block;
                }
            }

            .inner-breadcrum-hover {
                display: none;
            }
        }




    }

    .specialization-description {
        color: #fff;
        background-color: #354f83;
        padding: 25px 200px 40px;
        line-height: 1.8;
        font-size: 1rem;
    }

    .specialization-section {
        padding: 50px 200px;

        .specialization-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .why {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-content: center;
                justify-items: center;
                row-gap: 50px;
                width: 100%;

                &.afps {
                    display: flex;

                    .cards {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        align-content: center;
                        justify-items: center;
                        row-gap: 50px;
                        width: 100%;
                    }

                    .title {
                        width: auto;
                    }
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    width: 100%;
                }

                .why-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // width: 240px;
                    width: 300px;

                    .why-icon {
                        width: 80%;
                        margin-bottom: 15px;
                    }

                    .content {
                        margin-left: -10px;
                        display: flex;
                        gap: 10px;
                        align-items: flex-start;
                        font-size: 1.8rem;
                        font-weight: 500;
                        color: #2f4f87;

                        img {
                            height: 100%;
                        }
                    }
                }
            }

            .accordian {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;

                .outer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 25px;

                    &:hover {
                        background-color: #dcdcdc;
                        cursor: pointer;
                    }
                }

                &.currriculum {
                    margin-top: 20px;
                }
            }



            .currriculum_design {
                width: 100%;
                // margin-top: 50px;

                .currriculum_grade_section {
                    width: 100%;

                    &.section_change {
                        margin-top: 50px;
                    }

                    .heading {
                        // width: 100%;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        align-content: center;
                        justify-items: center;
                        padding: 25px 50px 35px;

                        .horizontal-border {
                            border-bottom: solid 5px #fee26b;
                            width: 100%;
                            // padding: 1px 0;
                        }

                        .grade {
                            margin-bottom: -10px;
                            color: #8298ed;
                            font-weight: 700;
                            font-size: 25px;
                        }
                    }

                    .currriculum_subjects {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        align-content: center;
                        justify-items: center;
                        gap: 1rem;

                    }

                    .currriculum_container {
                        width: 100%;

                        .currriculum_heading {
                            background-color: #173467;
                            text-align: center;
                            padding: 15px 0;
                            color: #e5eafc;
                            font-size: 1.2rem;
                            margin-bottom: 16px;

                            &.left {
                                border-top-left-radius: 10px;
                                border-bottom-left-radius: 10px;
                            }

                            &.right {
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }

                            &.all {
                                border-radius: 15px;
                            }
                        }

                        .currriculum_description {
                            background-color: #e2ebfa;
                            padding: 25px 50px;
                            height: 95%;

                            ul {
                                color: #122c5f;
                                font-size: 24px;
                                font-weight: 500;
                                margin: 0;
                                padding: 0;

                                li {
                                    list-style: none;
                                    padding: 15px 0;
                                }
                            }

                            .paragraph {

                                .title {
                                    color: #021741;
                                    font-size: 1.3rem;
                                    font-weight: 600;
                                }

                                .sub-title {
                                    font-size: 1.3rem;
                                    font-weight: 600;
                                    color: #1f4ca5;
                                }

                                .desc {
                                    color: #707070;
                                    font-weight: 500;
                                }

                                &.afps {
                                    .desc {
                                        padding: 10px 0;
                                    }
                                }
                            }

                            &.left {
                                border-bottom-left-radius: 25px;
                            }

                            &.right {
                                border-bottom-right-radius: 25px;
                                display: flex;
                                flex-direction: column;
                                gap: 3rem;
                                line-height: 1.5;
                            }

                            &.all {
                                border-radius: 15px;
                            }
                        }
                    }
                }
            }

            .creators_container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2rem;
                margin-top: 50px;
                padding: 0 50px;

                .images_container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 1rem;

                    &.pva {
                        img {
                            width: 200px;
                            height: auto;
                        }
                    }
                    img {
                        width: 120px;
                        height: 120px;
                    }

                    .center {
                        margin-left: 100px;
                        margin-top: -25px;
                        margin-bottom: -25px;

                    }
                }

                .content_container {
                    color: #3b3b40;
                    text-align: justify;

                    a {
                        color: #2f4f87;
                    }

                    i {
                        font-weight: 500;
                    }
                }
            }

        }
    }

    .career_pathways {
        img {
            width: 100%;
        }
    }

    .elegibility_criteria_section {
        padding: 0 150px 50px;

        .title {
            padding: 0 50px;
        }

        .elegibility_criteria_container {
            position: relative;

            .elegibility_criteria_container2 {
                margin-top: 15px;
                padding: 50px;
                border-radius: 25px;
                background-color: #e2ebfa;
                text-align: center;

                p {
                    text-align: left;
                    color: #43474e;
                    line-height: 1.56;
                    font-weight: 500;
                }

                button {
                    border: none;
                    outline: none;
                    padding: 10px 20px;
                    border-radius: 5px;
                    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
                    background-color: #db6966;
                    color: #fff;
                    font-size: 14px;
                }
            }

            .border {
                position: absolute;
                bottom: 0;
                background-color: #8298ed;
                height: 5px;
                width: 90%;
                left: 5%;
            }
        }

        .map-container {
            border-radius: 30px;
            margin-top: 100px;
            height: 60vh;

            iframe {
                border: solid 5px #354f83;
                border-radius: 50px;

            }
        }

        .testinomials_heading {
            margin-top: 70px;
        }
    }

    .title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.5;
        color: #021741;

        span {
            color: #5b5bc1;
        }
    }

    .testimonials_section {
        background-color: #fff;
        box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);

        padding: 50px 150px;

        .testimonials_container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            .image {
                border: solid 4px #415988;
                border-radius: 50%;

                img {
                    margin: 4px 4px 0 4px;
                    border: solid 1px #fff;
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                }
            }

            .desc {
                font-weight: 500;
                text-align: left;
                line-height: 1.8;

                .para {
                    color: #3b3b40;
                    padding-bottom: 20px;
                }

                .name {
                    color: #8298ed;
                    font-size: 1.2rem;
                }

                .post {
                    font-size: 1.2rem;
                    color: #5b5bc1;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .specialization {
        .banner {

            .inner-breadcrum-container {
                bottom: 5px;
                font-size: 16px;
                padding: 10px 30px;

                &:hover {
                    padding-left: 10%;
                    transition: all 1s;
                }
            }

        }

        .specialization-description {
            padding: 25px;
        }

        .specialization-section {
            padding: 25px;

            .specialization-content {
                .why {
                    grid-template-columns: 1fr;
                    row-gap: 20px;

                    &.afps {
                        flex-direction: column;

                        .cards {
                            grid-template-columns: 1fr
                        }
                    }

                    .title {
                        text-align: center;
                        align-items: center;
                    }
                }

                .currriculum_design {
                    .currriculum_grade_section {

                        .heading {
                            grid-template-columns: 1fr 3fr 1fr;
                            padding: 25px 30px 35px;
                        }

                        .currriculum_subjects {
                            gap: 10px;
                        }

                        .currriculum_container {
                            .currriculum_heading {
                                padding: 25px 0;
                                font-size: 16px;
                            }

                            .currriculum_description {
                                padding: 15px;
                                height: 95%;

                                ul {
                                    font-size: 16px;
                                }

                            }
                        }
                    }
                }

                .creators_container {
                    flex-direction: column;
                    gap: 1rem;
                    padding: 0;
                }
            }
        }

        .elegibility_criteria_section {
            padding: 25px;

            .title {
                text-align: center;
            }

            .elegibility_criteria_container {

                .elegibility_criteria_container2 {
                    padding: 25px;
                }

                .border {
                    position: absolute;
                    bottom: 0;
                    background-color: #8298ed;
                    height: 5px;
                    width: 90%;
                    left: 5%;
                }
            }

            .testinomials_heading {
                margin-top: 70px;
            }
        }


        .testimonials_section {
            padding: 25px;

            .testimonials_container {
                flex-direction: column;


                .desc {
                    text-align: justify;
                }
            }
        }
    }
}