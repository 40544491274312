.admissions_section {
    .admissions_banner {
        background-color: #2f4f87;
        padding: 150px 150px 20px;
        color: #f0f5fd;
        font-size: 2rem;

        .heading {
            padding-left: 40px;
        }

        .banner_container {
            margin: 30px 0;
            background-color: #f0f5fd;
            padding: 25px 50px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                padding: 12px 35px;
                border-radius: 5px;
                cursor: pointer;
                font-size: 1rem;
                border: solid 2px #2f4f87;
                background-color: #2f4f87;
                color: #e5eafc;
                font-weight: 600;

                &:hover {
                    background-color: #e5eafc;
                    color: #2f4f87;
                }

                &.active {
                    background-color: #e5eafc;
                    color: #2f4f87;
                }
            }
        }

        .active_heading {
            padding-left: 40px;
        }
    }

    .admission_content {
        background-color: #f2f2f2;
        padding: 20px 150px 50px;

        .admission_content_container {
            padding: 0 40px 40px;
            font-size: 1rem;

            .heading {
                font-size: 1.2rem;
                font-weight: 500;
                color: #122c5f;
                margin-bottom: 5px;
            }

            .para {
                color: #3b3b40;
                button {
                    margin-top: 24px;
                    border: none;
                    outline: none;
                    padding: 10px 20px;
                    border-radius: 5px;
                    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
                    background-color: #8298ed;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }

        .timeline {
            img {
                width: 100%;
            }
        }

        .apply {
            padding: 50px 0;
            text-align: center;

            button {
                padding: 20px 50px;
                border-radius: 5px;
                background-color: #db6966;
                font-size: 18px;
                color: #fff;
            }
        }
    }
}

@media (max-width: 1000px) {
    .admissions_section {
        .admissions_banner {
            padding: 50px 25px;

            .heading {
                padding-left: 20px;
            }

            .banner_container {
                padding: 25px;
                flex-direction: column;
                gap: 1rem;

                button {
                    width: 70%;
                }
            }

            .active_heading {
                padding-left: 20px;
            }
        }

        .admission_content {
            padding: 20px;

            .admission_content_container {
                padding: 0 20px 20px;
            }
        }
    }
}