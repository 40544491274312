.asoseheaderpic {
    margin-top: -5px;
    position: relative;

    img {
        width: 100%;
    }

    .breadcrum {
        position: absolute;
        bottom: 30px;
        left: 0;
        padding: 25px;
        background-color: #445c86;
        color: #edeff4;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        font-size: 30px;

        span {
            color: #8298ed;
        }

    }
}

.AboustASose_container {
    background-color: #f2f2f2;
}

.AboutASoSE {
    padding: 30px 150px;
    font-size: 1rem;
    text-align: left;
    background-color: #f2f2f2;
}

.visionmission-container {
    padding: 0px 150px;
    // margin-top: 30px;
    font-size: 1.8rem;

    .vision {
        background-color: #f2f2f2;

        span {
            color: #5b5bc1;
        }
    }

    .mission {
        padding: 15px 0;
        font-size: 1rem;
        text-align: left;
        background-color: #f2f2f2;
    }

    .datalist {
        padding: 0px 90px;
        font-size: 1rem;
        text-align: left;
    }
}

.Infrastructure-partnership {
    padding: 20px 0 0 0;

    .aboutasose {
        padding: 0 150px 30px;
        font-size: 1.3rem;
        text-align: left;
        display: flex;
        gap: 70px;
        color: #fff;
        align-items: center;
        font-weight: 600;

        .icon-container {
            img {
                height: 200px;
            }
        }

        &.class_1 {
            background-color: #2f4f87;
        }

        &.class_2 {
            background-color: #3b5d9a;
        }

        &.class_3 {
            background-color: #476caf
        }
    }

}

.ASoSEStoryHding {
    padding: 20px 150px;
    margin-top: 30px;
    font-size: 1.8rem;
    background-color: #f2f2f2;

    span {
        color: #5b5bc1;
    }
}

.ASoSEStoryfirst {
    padding: 30px 150px;
    font-size: 1rem;
    text-align: left;
    background-color: #f2f2f2;
}

.ASoSEStorysecond {
    padding: 30px 150px;
    font-size: 1rem;
    text-align: left;
    background-color: #f2f2f2;
}

.extra-curricular-section {
    padding: 40px 240px;

    .extra-curricular {
        display: grid;
        grid-template-columns: 9fr 2fr 9fr;
        justify-items: center;
        align-content: center;



        .stats-container {
            background-color: #F0F5FD;
            border: solid 10px #CDDEF8;
            width: 100%;

            .stats {
                padding: 50px;
                font-size: 20px;
                line-height: 1.2;
                font-weight: 500;

                .number {
                    color: #8298ed;
                    font-weight: 600;
                    font-size: 2rem;
                }

                .subhead {
                    color: #2f4f87;
                }

                .desc {
                    color: #5b5bc1;
                    padding-top: 25px;
                }
            }
        }

        .verticle {
            border-right: dashed 3px #9494a5;
        }

        .horizontal {
            border-top: dashed 3px #9494a5;
            width: 100%;
            margin: 25px 0;
        }
    }
}

.subjects-container {
    .sub-desc {
        padding: 20px 150px;
        font-size: 25px;
        text-align: left;
        background-color: #f2f2f2;
    }

    .sub-logos {
        display: flex;
        font-size: 25px;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding: 0 150px;

        .Subjects {
            display: flex;
            align-items: center;
            background-color: #dce6f7;
            width: 300px;
            margin-left: -50px;
            z-index: 1;
            height: 100px;
            padding: 50px 50px 25px 70px;
            color: #2f4f87;
            font-weight: 500;

            &.curved-top {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                padding-top: 70px;
            }

            &.curved-bottom {
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                padding-bottom: 70px;
            }
        }

        .Subjects-details {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #D6DCF1;
            width: 800px;
            height: 100px;
            padding: 25px 25px 25px 50px;
            margin-left: -50px;
            z-index: 3;
            border-left: solid 5px #2C53A7;
            color: #2f4f87;
            font-size: 1.3rem;
        }

        .logo-container {
            display: flex;
            align-items: center;
            padding-top: 30px;

            img {
                height: 120px;
                z-index: 2;
            }

        }
    }
}

.Map {
    padding: 40px 150px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;

    .locations {
        width: 100%;
        background-color: #fff;
        padding: 2rem;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;

        .locations_section {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 1.3rem;
            .locations-section-heading {
                font-weight: 600;
                text-align: center;
                margin-bottom: 1rem;
            }
            .locations-table {
                padding: 0.5rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                &:hover {
                    background-color: #f2f2f2;
                }
                .locations-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 2rem;
                }
            }
        }
    }
}

.MapPic {
    padding: 20px 150px;
    height: 65vh;
}

.aff {
    padding: 30px 150px 0;
    font-size: 1.8rem;
    font-weight: 500;
    background-color: #f2f2f2;
    color: #5b5bc1;
}

.Aff-container {
    display: flex;
    align-items: center;
    padding: 30px 150px;
    gap: 50px;

    .aff-desc {
        font-size: 1rem;
        color: #43474e;
        background-color: #f2f2f2;
    }

    .DbseLogo {
        img {
            height: 150px;
        }
    }
}

@media (max-width: 1000px) {

    .Map {
        padding: 40px 25px;
    }

    .MapPic {
        padding: 20px 25px;
    }

    .asoseheaderpic {
        .breadcrum {
            padding: 10px 20px;
            font-size: 16px;
        }
    }

    .AboutASoSE {
        padding: 25px;
    }

    .visionmission-container {
        padding: 25px;

        .datalist {
            padding: 0;
            padding-left: 25px;
        }
    }

    .Infrastructure-partnership {
        .aboutasose {
            padding: 25px;
            flex-direction: column;
        }
    }

    .ASoSEStoryHding {
        padding: 25px;
    }

    .ASoSEStoryfirst {
        padding: 25px;
    }

    .ASoSEStorysecond {
        padding: 25px;
    }

    .extra-curricular-section {
        padding: 25px;

        .extra-curricular {
            grid-template-columns: 1fr;
            gap: 1rem;

            .verticle {
                display: none;
            }

            .horizontal {
                display: none;
            }
        }
    }

    .subjects-container {
        .sub-desc {
            padding: 25px;
        }

        .sub-logos {
            padding: 25px;
            flex-direction: column;
            gap: 15px;

            .Subjects {
                &.curved-top {
                    padding-top: 25px;
                }

                padding: 25px 30px;
                width: auto;
                height: auto;
                margin: 0;
            }

            .Subjects-details {
                margin: 0;
                width: auto;
                height: auto;
            }
        }
    }

    .aff {
        padding: 25px;
        text-align: center;
    }

    .Aff-container {
        padding: 25px;
        flex-direction: column-reverse;
        align-items: center;

        .aff-desc {
            text-align: justify;
        }
    }
}

.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.fl-table td,
.fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}

.fl-table thead th {
    color: #ffffff;
    background: #324960;
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}