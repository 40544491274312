.faq {
    .faq_section {
        .faq_container {
            background-color: #f2f2f2;
            padding: 50px 200px;

            .title {
                color: #2f4f87;
            }
        }
    }
}

@media (max-width: 1000px) {
    .faq {
        .faq_section {
            .faq_container {
                padding: 50px 25px;
            }
        }
    }
    .accordion__button {
        width: auto;
    }
}