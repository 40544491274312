.topHeader {
    width: 100%;
    // height: 30px;
    background-color: #354f83;

    .topHeaderContainer {
        padding: 6px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        line-height: 1.5;

        .language {
            display: flex;
            align-items: center;
            gap: 1.5rem;

            span {
                cursor: pointer;
            }
        }
    }
}

.links-bottom-mobile {
    display: none;
}

button {
    cursor: pointer;
    outline: none;
    border: none;
}

.mainHeader {
    background-color: #f2f2f2;
    padding: 20px 100px;

    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logos {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;

            img {
                width: 100px;
            }
        }

        .links {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-end;
            justify-content: center;

            .links-top {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 1rem;

                button {
                    padding: 10px 20px;
                    border-radius: 5px;
                    background-color: #8298ed;
                    font-size: 16px;
                    color: #fff;
                    margin-bottom: 3px;
                }

                .hamburger {
                    display: none;
                }
            }

            .links-bottom {
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    li {
                        list-style: none;
                        font-size: 18px;
                        font-weight: 500;
                        color: #122c5f;
                        position: relative;
                        cursor: pointer;

                        &.active {
                            font-weight: 700;
                            border-bottom: solid 2px #8298ed;
                        }

                        &:hover {
                            font-weight: 700;
                            border-bottom: solid 2px #8298ed;
                        }

                        span {
                            color: inherit;
                            text-decoration: none;
                        }

                        &.specialization_tab {
                            .sub-section {
                                display: none;
                            }

                            &:hover {
                                .sub-section {
                                    display: block;
                                }
                            }
                        }

                        .sub-section {
                            position: absolute;
                            left: 0;
                            margin-bottom: 20px;
                            // top: 40px;
                            z-index: 9;
                            width: 100%;
                            width: -webkit-fill-available;
                            border-radius: 5px;
                            background-color: #e7ecf7;
                            padding: 15px 10px 20px;
                            color: #021741;
                            display: none;

                            .sub-container {
                                display: flex;
                                flex-direction: column;

                                span {
                                    font-weight: 400;
                                    padding: 10px 0;
                                    border-bottom: solid 1px #bac0cb;

                                    &:hover {
                                        font-weight: 600;
                                        border-bottom: solid 2px #8298ed;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.topBreadcrum {
    background-color: #354f83;
    color: aliceblue;
    padding: 15px 100px;
    font-size: 18px;

    span {
        &.colored {
            color: #fee26b;
        }
    }
}


.specializationGallery {
    padding: 75px 0 100px;

    .specializationGallery_section {
        background-color: rgba($color: #8298ed, $alpha: 0.42);

        .specializationGallery_container {
            padding: 0 75px;
        }
    }
}

.footer-section {
    border-top: solid 7px #354f83;
    padding: 25px 150px;
    text-align: center;

    .footer-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer-branding {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;

        span {
            img {
                width: 120px;
            }
        }
    }
}

@media (max-width: 1445px) {
    .specializationGallery {
        .specializationGallery_section {
            .specializationGallery_container {
                padding: 0;
            }
        }
    }
}

@media (max-width: 1000px) {
    .mainHeader {
        padding: 20px;

        .links-bottom {
            display: none;
        }

        .headerContainer {
            .logos {
                img {
                    width: 70px;
                }
            }

            .links {
                .links-top {
                    gap: 5px;

                    .hamburger {
                        display: block;
                        scale: 0.7;
                    }
                }
            }
        }
    }

    .topHeader {
        .topHeaderContainer {
            flex-direction: column;
            gap: 0.3rem;
        }
    }

    .topBreadcrum {
        padding: 15px 25px;
    }

    .links-bottom-mobile {
        display: block;
        padding: 50px 0;

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 20px;

            li {
                list-style: none;
                font-size: 18px;
                font-weight: 500;
                color: #122c5f;
                position: relative;
                width: 80%;

                &.active {
                    font-weight: 700;
                    border-bottom: solid 2px #8298ed;
                }

                &:hover {
                    font-weight: 700;
                    border-bottom: solid 2px #8298ed;
                }

                span {
                    color: inherit;
                    text-decoration: none;
                }

                &.specialization_tab {
                    .sub-section {
                        display: none;
                    }

                    &:hover {
                        .sub-section {
                            display: block;
                        }
                    }
                }

                .sub-section {
                    margin-bottom: 20px;
                    margin-top: 10px;
                    z-index: 9;
                    width: 100%;
                    width: -webkit-fill-available;
                    border-radius: 5px;
                    background-color: #e7ecf7;
                    padding: 05px 10px 20px;
                    color: #021741;
                    display: none;

                    .sub-container {
                        display: flex;
                        flex-direction: column;

                        span {
                            font-weight: 400;
                            padding: 10px 0;
                            border-bottom: solid 1px #bac0cb;

                            &:hover {
                                font-weight: 600;
                                border-bottom: solid 2px #8298ed;
                            }
                        }
                    }
                }
            }
        }

    }

    .footer-section {
        padding: 25px;

        .footer-container {
            flex-direction: column;
            gap: 1rem;
        }

        .footer-branding {
            gap: 1.5rem;

            span {
                img {
                    width: 70px;
                }
            }
        }
    }

    .specializationGallery {
        .specializationGallery_section {
            .specializationGallery_container {
                padding: 0 75px;
            }
        }
    }
}

@media (max-width: 400px) {
    .mainHeader {
        padding: 10px;
    }
}