.home {
    .banner {
        margin: 0;
        padding: 0;

        img {
            width: 100%;
        }
    }

    .dcmMessage {
        background-color: #f2f2f2;
        padding: 20px 100px;

        .updates {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            .updateHeading {
                background-color: #db6966;
                font-size: 16px;
                padding: 4px 17px 4px 27px;
                color: #fff;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
            }

            .marquee {
                background-color: #f0f5fd;
                color: #2f4f87;
                padding: 4px 24px;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                width: 100%;

                marquee {
                    display: flex;
                    gap: 1rem;
                }
            }
        }

        .messageContainer {
            padding: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            background-color: #f2f5fd;

            .dcmPic {
                img {
                    width: 100%;
                }
            }

            .message {
                padding: 15px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .content {
                    color: #707070;
                    font-size: 18px;
                    margin-bottom: 1.5rem;
                }

                .name {
                    color: #1f4ca5;
                    font-weight: 700;
                    font-size: 21px;
                }

                .position {
                    color: #43474e;
                    font-size: 18px;
                    font-weight: 500;
                }

            }
        }
    }

    .specialization {
        background-color: #8298ed10;
        padding: 50px 150px;

        .specialization_container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;
            width: 100%;

            .specialization_topic {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                color: #021741;
                font-size: 2.2rem;
                font-weight: 600;

                span {
                    color: #5b5bc1;
                }
            }

            .specialization_card {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding-top: 20px;
                cursor: pointer;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                border: solid 2px #2f4f87;
                background-color: #fff;

                &:hover {
                    background-color: #122c5f;
                    transition: all 0.3s;

                    .topic {
                        color: #fff;
                    }

                    .view-more {
                        background-color: #fee26b;
                        color: #2f4f87;
                    }
                }

                .icon {
                    margin-bottom: 20px;
                }

                .topic {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.48;
                    color: #2f4f87;
                    text-align: center;
                    margin-bottom: 15px;
                }

                .view-more {
                    width: 100%;
                    text-align: center;
                    color: #fee26b;
                    padding: 10px 0;
                    background-color: #2f4f87;
                }
            }
        }
    }

    .notices {
        background-color: #f2f2f2;
        padding: 50px 150px;

        .notices_heading {
            color: #021741;
            font-size: 2.2rem;
            font-weight: 600;

            span {
                color: #5b5bc1;
            }
        }

        .notices_container {
            margin: 30px 0;
            background-color: #fff;
            padding: 50px 30px;
            border-radius: 25px;

            ul {
                max-height: 60vh;
                overflow-y: scroll;
                padding-right: 60px;

                li {
                    list-style: none;
                    color: #122c5f;
                    font-weight: 500;
                    padding: 10px 0;
                    border-bottom: solid 1px #8298ed;
                    cursor: pointer;
                    a {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .partners {
        marquee {

            background-color: #f0f5fd;
            padding: 50px 150px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            .logos {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;

                img {
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .home {
        .dcmMessage {
            padding: 20px;

            .messageContainer {
                grid-template-columns: 1fr;
                padding: 25px;
            }
        }

        .specialization {
            padding: 50px 25px;

            .specialization_container {
                grid-template-columns: 1fr;

                .specialization_topic {
                    align-items: center;
                }
            }
        }

        .notices {
            padding: 50px 25px;

            .notices_heading {
                text-align: center;
            }

            .notices_container {
                padding: 25px;

                ul {
                    max-height: 70vh;
                    padding: 0;
                }
            }
        }

        .partners {
            marquee {
                padding: 25px;

                .logos {
                    gap: 1rem;
                }
            }
        }
    }
}